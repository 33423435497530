// /* Sidenotes */
//

// The following code is here for reference, It is needed, however in my setup, if it is placed here, it causes other counters to fail.
// The counter sidenote_counter is reset in the theorem.scss file in the directory.
// body {
//   counter-reset: sidenote_counter;
// }

.marginnote::before,
.sidenote::before {
  content: counter(sidenote_counter) " ";
  position: relative;
  top: -0.5em;
  vertical-align: baseline;
  font-size: 0.8rem; // Font-size of the number in the sidenote
}

.marginnote,
.sidenote {
  float: right;
  clear: right;
  width: 15vw;
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
  max-width:         calc(#{$content-width} - (#{$spacing-unit}));
  margin-right: -17.5vw;
  position: relative;
  vertical-align: baseline;
  padding-left: $spacing-unit*0.5;
  margin-bottom: 0.25em;
  // padding-bottom: 5px;
  border-left: 1px solid $grey-color-light;
  // text-align: left;
  font-size: $small-font-size;
  color: $grey-color;
  font-style: italic;
}

.marginnote::after,
.sidenote::after {}
// These don't currently work
.sidenote-wrapper:hover .sidenote {
  color: $color-blue;
  border-color: $color-blue;
}

.sidenote-wrapper:hover .sidenote-number {
  color: $color-blue;
  border-color: $color-blue;
}
// .sidenote.left {
//     float: left;
//     margin-left: -20vw;
//     margin-bottom: $spacing-unit;
//   }

.sidenote-number::before {}

.sidenote-number {
  counter-increment: sidenote_counter;
}

.sidenote-number::after {
  content: counter(sidenote_counter)" ";
  font-size: 0.8rem;
  top: -0.5rem;
  // left: 0.1rem;
  font-family: et-book-roman-old-style;
  position: relative;
  vertical-align: baseline;
}
// .marginnote > code, .sidenote > code { font-size: 1rem; }
input.margin-toggle {
  display: none;
}

label.sidenote-number {
  display: inline;
}

label.margin-toggle:not(.sidenote-number) {
  display: none;
}
@media(max-width: 1400px) {
  label.margin-toggle:not(.sidenote-number) {
    display: inline;
  }

  .marginnote,
  .sidenote {
    display: none;
  }

  .sidenote-number::after {
    content: counter(sidenote_counter)" ";
    color: $color-blue;
  }

  .sidenote-wrapper:hover .sidenote {
    color: $color-blue;
    border-color: $color-blue;
  }

  .sidenote-wrapper:hover .sidenote-number::after {
    color: $color-blue;
    border-color: $color-blue;
  }

  .margin-toggle:checked + .marginnote,
  .margin-toggle:checked + .sidenote {
    display: block;
    float: left;
    // left: 1rem;
    clear: both;
    width: 90vw;
    // padding-left: 3vw;
    margin: 5px 1rem;
    vertical-align: baseline;
    // position: relative;
  }
}
