// Options for Table of contents.



.page-toc {
  width: 100%;

  ul {
    display: grid;
    margin-left: 0;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
    gap: 0.5rem;
    list-style: none;
  }

  ul>li {
    @include relative-font-size(0.75);
    border-radius: 15px;
    border: 1px solid #78909C;
    display: grid;
    height: 100%;
    // width: 180px;
    list-style: none;
  }

  ul>li a {
    // height: 100%;
    display: grid;
    text-align: center;
    align-items: center;
    border-radius: 15px;
    @include theme("color", $text-color);
    // color: black;
    // flex: 1 1 0px;
    @include theme("background-color", $grey-color-light);
    // background-color: $grey-color-light;
    text-align: center;
    padding: 5px;
    text-decoration: none;
  }

  li a:hover {
    @include theme("background-color", $page-link-hover-colour);
  }
}



// Options for NavBar Dropdown
.dropbtn {
  overflow: hidden;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;

  page-link {
    padding: 0px 0px;
  }
}

.page-link:hover,
.dropdown:hover .dropbtn .dropdown-content {
  @include theme("background-color", $page-link-hover-colour);
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;

}

/* Links inside the dropdown */
.dropdown-content a {
  // color: $text-color;
  @include theme("color", $text-color);
  line-height: $base-line-height;
  padding: 12px 10px;
  // color: black;
  @include theme("color", $text-color);
  text-decoration: none;
  display: block;
  float: none;

}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: $grey-color-light;
  text-decoration: underline;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

// // /* Change the background color of the dropdown button when the dropdown content is shown */
// .dropdown:hover .dropbtn {
//   background-color: #3e8e41;
// }




.arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.down {
  margin-bottom: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.single-image {
  text-align: center;

  img {
    @include theme("filter", $invert-colour);
    width: 50%;
    padding-right: 10px;
    padding-left: 10px;
  }
}

.image_block {
  @include theme("color", $text-color);
  text-align: center;

  img {
    padding-right: 25px;
    padding-left: 25px;
  }

  p {
    @include theme("color", $text-color);

  }
}

.home-heading {
  @include relative-font-size(1.75);
}

// .site-header {
//   // background-color: $color-blue;
//   // opcaity: 0.65;
// }
// /** Pages **/
// .page-header{
//   // margin-bottom: $spacing-unit
// }

.page-title {
  @include relative-font-size(2);
  letter-spacing: -1px;
  // line-height: 1;

  @include media-query($on-laptop) {
    @include relative-font-size(2);
  }
}

.page-content {
  // padding: $spacing-unit 0;
  flex: 1;

  // h1 {
  //   text-align: center;
  // }
  h2 {
    @include relative-font-size(1.5);
    text-decoration: underline;

    @include media-query($on-laptop) {
      @include relative-font-size(1.5);
    }
  }

  h3 {
    @include relative-font-size(1.25);
    line-height: 1;
    text-decoration: underline;

    @include media-query($on-laptop) {
      @include relative-font-size(1.25);
      // text-decoration: underline;
      line-height: 1;
    }
  }

  h4 {
    @include relative-font-size(1.1);

    @include media-query($on-laptop) {
      @include relative-font-size(1.1);
    }
  }

  .title_and_summary h4 {
    display: inline;
  }

  .title_and_summary span {
    float: right;
  }
}


details {
  margin: 1rem 0rem 1rem 0rem;
  padding: 0rem 0rem 0rem 1rem;
  // width: 35em;
  max-width: calc(100% - 1rem);
  position: relative;
  border: 1px solid #78909C;
  border-radius: 6px;
  // background-color: #FFF;
  @include theme("background-color", $grey-color-light);

  // color: #263238;
  @include theme("color", $text-color);
  transition: background-color .15s;

  > :last-child {
    margin-bottom: 1em;
  }

  &::before {
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border-radius: inherit;
    opacity: .15;
    box-shadow: 0 .25em .5em #263238;
    pointer-events: none;
    transition: opacity .2s;
    z-index: -2;
  }

  &[open] {
    @include theme("background-color", $grey-color-light);

    // background-color: #FFF;
    &::before {
      opacity: .6;
    }
  }

  pre,
  code {
    border: none
  }
}

summary {
  padding: 0.1em 0.7em 0.1em 0.5em;
  display: block;
  position: relative;
  margin: 0em 0em 1em 0.5em;
  left: -1em;
  // right: 0.5em
  // max-width: calc(100% - 2rem);
  @include relative-font-size(1);
  cursor: pointer;

  &::before,
  &::after {
    width: .75em;
    height: 2px;
    position: absolute;
    top: 50%;
    right: -0.5em;
    font-size: 1em;
    content: ' ';
    @include theme("background-color", $text-color);
    text-align: right;
    transform: translateY(-50%);
    transition: transform .2s ease-in-out;
  }

  &::after {
    transform: translateY(-50%) rotate(90deg);

    [open] & {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  &::-webkit-details-marker {
    display: none;
  }

  p {
    margin: 0.5em;
  }

  pre,
  code {
    border: none;
  }
}


.arrow-img {
  @include theme("filter", $invert-colour);
  // filter: invert(100%);
}

.greyscale-img {
  @include theme("filter", $invert-colour);
}