// Mathematics Theorem, Definition and Lemma environments
//  My Custom colors
$def-colour:       rgb(145, 141, 251);
$thm-colour:       rgb(255, 104, 105);
$lem-colour:       rgb(98,126,110);


body {
  // Produce counters (for that every page) to automatically number the lemma, theorem and definition environments.
  counter-reset: definition theorem lemma example question sidenote_counter;
}


// Settings for all environments
.definition, .theorem, .lemma, .example, .question{
    display: block;
    clear:left;
    margin: 5px;
    // margin-left: 15px;
    // margin-right: 5px;
    padding-left: 10px;
    padding-right:10px;
    font-style: normal;
}
.definition:before, .theorem:before, .lemma:before, .example:before, .question:before{
  font-weight: bold;
  font-style: normal;
}

.question {
	border: 4px solid rgb(calc(255/2),0,calc(255/2));
  // margin:5px
}
.question:before{
  content: "Question " counter(question) ".";
  counter-increment: question;
}

.question[name]:before {
    content: "Question " counter(question) ". (" attr(name) ") ";
    counter-increment: question;
}

.example {
  @include theme("border-left", $example-border);
}
.example:before{
  content: "Example " counter(example) ".";
  counter-increment: example;
}
.example[name]:before{
  content: "Example " counter(example) ". (" attr(name) ") ";
  counter-increment: example;
}

.definition{border: 4px solid $def-colour;}
.definition:before { // The default environment
    content: "Definition " counter(definition) ".";
    counter-increment: definition;
}
.definition[name]:before{ // When the definition has been given a name
  content: "Definition " counter(definition) ". (" attr(name) ") ";
  counter-increment: definition;
}

// Settings for the definition environment
.theorem{border: 4px solid $thm-colour;}
.theorem:before { // The default theorem environment
    content: "Theorem " counter(theorem) ".";
    counter-increment: theorem;
}
.theorem[name]:before{ // When the theorem has been given a name
  content: "Theorem " counter(theorem) ". (" attr(name) ") ";
  counter-increment: theorem;
}

// Settings for the lemma environment
.lemma{border: 4px solid $lem-colour;}
.lemma:before { // The default lemma environment
    content: "Lemma " counter(lemma) ".";
    counter-increment: lemma;
}
.lemma[name]:before{ // when the lemma has been given a name
  content: "Definition " counter(lemma) ". (" attr(name) ") ";
  counter-increment: lemma;
}


.proof{
    display: block;
    clear:left;
    margin: 5px;
    // margin-left: 15px;
    // margin-right: 5px;
    // padding-left: 10px;
    // padding-right:10px;
    font-style: normal;
}

.proof:before {
    content: "Proof. ";
    font-weight: bold;
    font-size: normal;
    // white-space: pre;
}
.proof:after{
    content: "\25A1";
    position:relative;
    float:right;
    top:-30px;
    // white-space: nowrap;
}
