/**
 * Reset some basic elements
 */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
hr,
dl,
dd,
ol,
ul,
figure {
  margin: 0;
  padding: 0;
}



/**
 * Basic styling
 */
body {
  font: $base-font-weight #{$base-font-size}/#{$base-line-height} $base-font-family;
  @include theme("color", $text-color);
  @include theme("background-color", $background-color);
  -webkit-font-feature-settings: "kern" 1;
  -moz-font-feature-settings: "kern" 1;
  -o-font-feature-settings: "kern" 1;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
ul,
ol,
dl,
figure,
%vertical-rhythm {
  margin-bottom: calc($spacing-unit / 2);
}



/**
 * `main` element
 */
main {
  display: block;
  /* Default value of `display` of `main` element is 'inline' in IE 11. */
}



/**
 * Images
 */
img {
  max-width: 100%;
  vertical-align: middle;
}



/**
 * Figures
 */
figure>img {
  display: block;
}

figcaption {
  font-size: $small-font-size;
}



/**
 * Lists
 */
ul,
ol {
  margin-left: $spacing-unit;
}

li {

  >ul,
  >ol {
    margin-bottom: 0;
  }
}


/**
 * Headings
 */
h1,
h2,
h4,
h5,
h6 {
  font-weight: $base-font-weight;
}

h1,
h2 {
  text-align: center;
  text-decoration: underline;
}

h3 {
  text-decoration: underline;
}

/**
 * Links
 */
a {
  @include theme("color", $link-color);
  text-decoration: none;

  &:visited {
    @include theme("color", $link-color-visited);

  }

  &:hover {
    @include theme("color", $text-color);
    text-decoration: underline;
  }

  .social-media-list &:hover {
    text-decoration: none;

    .username {
      text-decoration: underline;
    }
  }
}


/**
 * Blockquotes
 */
blockquote {
  color: $text-color;
  @include theme("background-color", $grey-color-light);
  border-left: 4px solid $grey-color-light;
  padding-left: calc($spacing-unit / 2);
  @include relative-font-size(1.125);
  letter-spacing: -1px;
  font-style: italic;

  > :last-child {
    margin-bottom: 0;
  }
}



/**
 * Code formatting
 */
pre,
code {
  @include relative-font-size(0.9375);
  border: 1px solid $grey-color-light;
  border-radius: 3px;
  @include theme("background-color", $grey-color-light);
  // background-color: #eef;
}

code {
  padding: 1px 5px;
}

pre {
  padding: 8px 12px;
  overflow-x: auto;

  >code {
    border: 0;
    padding-right: 0;
    padding-left: 0;
  }
}



/**
 * Wrapper
 */
.wrapper {
  max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
  max-width: calc(#{$content-width} - (#{$spacing-unit} * 2));
  margin-right: auto;
  margin-left: auto;
  padding-right: $spacing-unit;
  padding-left: $spacing-unit;
  @extend %clearfix;

  @include media-query($on-laptop) {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
    max-width: calc(#{$content-width} - (#{$spacing-unit}));
    padding-right: calc($spacing-unit / 2);
    padding-left: calc($spacing-unit / 2);
  }
}



/**
 * Clearfix
 */
%clearfix:after {
  content: "";
  display: table;
  clear: both;
}



/**
 * Icons
 */

.svg-icon {
  width: 22px;
  height: 22px;
  display: inline-block;
  fill: #{$grey-color};
  padding-right: 5px;
  vertical-align: text-top;
}

.social-media-list {
  li+li {
    padding-top: 5px;
  }
}


/**
 * Tables
 */
table {
  margin-bottom: $spacing-unit;
  width: auto;
  max-width: 100%;
  text-align: $table-text-align;
  @include theme("color", $table-text-color);
  border-collapse: collapse;
  border: 2px solid $grey-color-light;
  border-spacing: 0;

  tr {
    &:nth-child(even) {
      @include theme("background-color", $table-background-colour-a);
    }

    &:nth-child(odd) {
      @include theme("background-color", $table-background-colour-b);
    }

  }

  th,
  td {
    padding: (calc($spacing-unit / 20)) (calc($spacing-unit / 10));
  }

  th {
    @include theme("background-color", $table-background-colour-header);
    border: 2px solid darken($grey-color-light, 10%);
    border-bottom-color: darken($grey-color-light, 12%);
    padding-left: $spacing-unit;
    padding-right: $spacing-unit;
  }

  td {
    border: 1px solid $grey-color-light;
  }
}

table tr>th[scope='col'] {
  border-bottom: 1.36px solid black;
}

table>tbody>tr:first-child>td,
table>tbody>tr:first-child>th {
  border-top: 1.36px solid black;
}


.vertical-heading table td:nth-child(1) {
  @include theme("background-color", $table-background-colour-header);
  padding-right: $spacing-unit;
  font-weight: bold;
}

// /* Better tables */
// table {
//   border-top: 2.27px solid black;
//   border-bottom: 2.27px solid black;
//   counter-increment: caption;
// }
// /* add bottom border on column table headings  */
// table tr > th[scope='col'] {
//   border-bottom: 1.36px solid black;
// }
// /* add right border on row table headings  */
// table tr > th[scope='row'] {
//   border-right: 1.36px solid black;
// }
// table > tbody > tr:first-child > td,
// table > tbody > tr:first-child > th {
//   border-top: 1.36px solid black;
// }
// table > tbody > tr:last-child > td,
// table > tbody > tr:last-child > th {
//   border-bottom: 1.36px solid black;
// }

.center-image {
  margin: 0 auto;
  display: block;
}